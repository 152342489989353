<template>
  <div>
    <div class="">
      <!-- <span @click="$router.push({ path: '/invest' })">←</span>
      <span class="txt">详情</span> -->
      <van-nav-bar :title="$t('购买')"  left-arrow @click-left="$router.push({ path: '/invest' })" />
    </div>
    <div class="main">
      <div class="item">
        <div class="title">
          <span class="icon">
            <img src="@/assets/img/btc.png" style="width:1.6rem">
            <span>{{ info.title }}</span>
          </span>

        </div>
        <div class="row">
          <div class="col">
            <div class="subtitle">{{ $t('限额') }}</div>
            <div class="num font-color2">{{ info.buy_min }} - {{ info.buy_max }}</div>
          </div>
          <div class="col">
            <div class="subtitle">{{ $t('日收益率') }}</div>
            <div class="num font-color">{{ info.rate }}%</div>
          </div>
          <div class="col">
            <div class="subtitle">{{ $t('周期') }}</div>
            <div class="num font-color2">{{ info.day }}({{ $t('天') }})</div>
          </div>
        </div>
        <div class="buy">
          <div class="txt">{{ $t('托管金额') }}</div>
          <div class="input">
            <input type="input" v-model="money" :placeholder="$t('订单数量')">
            <button @click="setMaxMoney">{{ $t('最大') }}</button>
          </div>
        </div>
        <div><button @click="setOrder">{{ $t('trade.confirm') }}</button></div>

      </div>
      <div class="info">
        <div><span>{{ $t('最大') }}:{{ info.buy_max }} USDT </span><span class="font-color2"
            @click="$router.push({ path: '/assets/recharge' })">{{ $t('存入资金') }}</span></div>
        <div><span class="font-color1">{{ $t('可购次数') }}:</span><span>{{ info.buy_use_num }}/{{ info.buy_num }}</span></div>
        <div><span class="font-color1">{{ $t('限额') }}:</span><span>{{ info.buy_min }}~{{ info.buy_max }} USDT</span></div>
        <div><span class="font-color1">{{ $t('总年化率') }}:</span><span class="font-color">{{ year_rate || 0 }}%</span></div>
        <div><span class="font-color1">{{ $t('预期收益') }}:</span><span>{{ total_money }} USDT</span></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: 0,
      userInfo: {},
      info: {},
      money: 0
    }
  },
  computed: {
    total_money() {
      return this.money * this.info.day * this.info.rate / 100
    },
    year_rate() {
      return this.info.buy_max * this.info.day * this.info.rate  / this.info.buy_max / this.info.day * 365 / 100
    }
  },
  created() {
    const { id } = this.$route.query
    this.id = id
    console.log('created=========', this.$route.query)
    this.getUserInfo()
    this.getInfo()
  },
  methods: {
    // 订单 信息
    getInfo() {
      const { id } = this
      this.$http.get('/invest/web/invest_info?id=' + id).then(e => {
        this.info = e.data.data
        console.log(e)
      })
    },
    // 用户信息
    async getUserInfo() {
      const { data } = await this.$http.get('/home/user/index')
      if (data) {
        if (data.code === 200) {
          this.userInfo = data.data
        }
      }
    },
    // 最大
    setMaxMoney() {
      console.log(this.userInfo)
      const { contract } = this.userInfo.balance
      const { buy_min, buy_max } = this.info
      let money = Number(buy_min)
      console.log(contract, buy_min, buy_max)
      money = Number(contract) < Number(buy_max) ? Number(contract) : Number(buy_max)
      this.money = money
    },
    // 下单
    setOrder() {
      const { buy_min, buy_max } = this.info
      let data = {
        id: this.id,
        money: this.money
      }
      if (Number(this.money) < Number(buy_min)) return this.$toast.fail('下单金额小于最小金额')
      this.$http.post('/invest/web/invest_order_save', data).then(e => {
        const { code, data, msg } = e.data
        if (code != 200) return this.$toast.fail(msg)
        this.$router.push("/invest");
        console.log(e)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.font-color {
  color: #64A192
}

.font-color1 {
  color: #CED3DB
}

.font-color2 {
  color: #0153C1
}

.topbar {
  display: flex;
  justify-content: space-between;
  height: 50px;
  align-items: center;

  .txt {
    flex: 1 1 90%;
    justify-self: center;
  }
}

.main {
  display: flex;
  flex-direction: column;

  .item {
    padding: 10px;
    background-color: #fff;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        display: flex;
        align-items: center;
      }
    }

    .row {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      .col {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .subtitle {
          color: #8C908F;
          font-size: 0.8rem;
          padding: 10px 0;
        }
      }
    }

    button {
      font-size: 1rem;
      font-weight: 400;
      color: #fff;
      background-color: #0153C1;
      height: 40px;
      line-height: 40px;
      border: none;
      border-radius: 4px;
      width: 100%;
    }

    .buy {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      wdith: 100%;

      .txt {
        margin: 10px 0;
        display: flex;
        justify-self: flex-start;
        font-size: 0.8rem;
        color: #8C908F;
      }

      .input {
        margin: 10px 0;
        display: flex;

        input {
          border: none;
          background-color: #F3F4F8;
          flex: 1;
          border-radius: 0.2rem 0 0 0.2rem;
        }

        button {
          flex-grow: 0;
          width: 5rem;
          border-radius: 0 0.2rem 0.2rem 0;
        }
      }

    }
  }

  .info {
    margin: 0 10px;
    background-color: #F3F4F8;
    padding: 10px;
    border-radius: 0.2rem;
    text-align: left;
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
}</style>
