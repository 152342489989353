import { render, staticRenderFns } from "./InvestForm.vue?vue&type=template&id=11b0f277&scoped=true&"
import script from "./InvestForm.vue?vue&type=script&lang=js&"
export * from "./InvestForm.vue?vue&type=script&lang=js&"
import style0 from "./InvestForm.vue?vue&type=style&index=0&id=11b0f277&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11b0f277",
  null
  
)

export default component.exports